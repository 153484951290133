@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn {
        @apply block mx-auto cursor-pointer justify-center items-center rounded-md w-max bg-blue text-white px-3 py-1 mt-2 select-none ring-2 ring-blue-light ring-opacity-50
    }
    input {
        @apply text-blue
    }
    [type='email']:focus {
        @apply ring-blue-light ring-opacity-50
    }
    [type='text']:focus {
        @apply ring-blue-light ring-opacity-50
    }
}