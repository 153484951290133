.react-calendar {
    border: none;
    font-family: GothamRounded-Bold;
    color: #22508a;
    width: 100%;
    .react-calendar__navigation {
        margin-bottom: 0;
        height: 1em;
        @media (min-width: 1024px) {
            height: 3em;
        }
    }
    abbr[title] {
        text-decoration: none;
    }
    button {
        font-size: 12px;
        @media (min-width: 1024px) {
            font-size: 16px;
        }
        outline: none;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.1;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #22508a;
    }
    .react-calendar__tile--now {
        background: #05a8cc;
        color: white;
        opacity: 0.5;
        border-radius: 50px;
    }
    .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus, .react-calendar__tile--active {
        background: #22508a;
        color: white;
        border-radius: 50px;
        opacity: 1;
    }
    .react-calendar__month-view__weekdays__weekday {
        font-size: 10px;
        @media (min-width: 1024px) {
            font-size: 14px;
        }
    }
    .react-calendar__tile {
        padding: 0.5em 0.5em;
    }
    .react-calendar__tile--hasActive {
        background: #22508a;
        color: white;
        border-radius: 50px;
    }
    .react-calendar__tile:disabled {
        opacity: 0.1;
        color: #757575;
        background: white;
    }
}