body {
    overscroll-behavior: contain;
    overscroll-behavior-y: none;
    -webkit-tap-highlight-color: transparent;
}
.sunbedres-header-container {
    height: 144px;
    @media (min-width: 1024px) {
        height: 192px;
    }
}
.event-booking-container {
    height: 97%;
    overflow: hidden;
    // @media (min-width: 1440px) {
    //     height: 80%;
    // }
    .event-booking-body {
        overflow: auto;
        max-height: calc(100% - 144px);
        @media (min-width: 1024px) {
            max-height: calc(100% - 91px);
        }
        .event-booking-content {
            overflow: auto;
        }
    }
}
.sunbedres-container {
    height: 97%;
    overflow: hidden;
    @media (min-width: 1024px) {
        height: 91%;
    }
    .sunbedres-body  {
        height: calc(100% - 144px);
        @media (min-width: 1024px) {
            height: calc(100% - 175px);
        }
        .sunbedres-content {
            overflow: auto;
        }
    }
}
input:disabled {
    opacity: 0.6;
    background: #e0e0e0;
}