@keyframes slideIn {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes rotateAppear {
    0% {
        opacity: 0;
        transform: rotate(0);
    }
    100% {
        opacity: 1;
        transform: rotate(-18deg);
    }
}
@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes shake-top {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;
    }
    20% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;
    }
    2% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
    4%,
    8%,
    12% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }
    6%,
    10%,
    14% {
        -webkit-transform: rotate(4deg);
                transform: rotate(4deg);
    }
    16% {
        -webkit-transform: rotate(-2deg);
                transform: rotate(-2deg);
    }
    18% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }
}
@keyframes shake-top {
    0%,
    100% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
        -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;
    }
    20% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      -webkit-transform-origin: 50% 0;
              transform-origin: 50% 0;
    }
    2% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
    4%,
    8%,
    12% {
      -webkit-transform: rotate(-4deg);
              transform: rotate(-4deg);
    }
    6%,
    10%,
    14% {
      -webkit-transform: rotate(4deg);
              transform: rotate(4deg);
    }
    16% {
      -webkit-transform: rotate(-2deg);
              transform: rotate(-2deg);
    }
    18% {
      -webkit-transform: rotate(2deg);
              transform: rotate(2deg);
    }
}
@-webkit-keyframes slide-out-blurred-tr {
    0% {
      -webkit-transform: translate(0, 0) skew(0deg, 0deg);
              transform: translate(0, 0) skew(0deg, 0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
              transform: translate(1000px, -1000px) skew(-80deg, -10deg);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
}
@keyframes slide-out-blurred-tr {
    0% {
      -webkit-transform: translate(0, 0) skew(0deg, 0deg);
              transform: translate(0, 0) skew(0deg, 0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
              transform: translate(1000px, -1000px) skew(-80deg, -10deg);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
}
@-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
}
@-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
}
@-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
@-webkit-keyframes flip-in-hor-bottom {
        0% {
          -webkit-transform: rotateX(80deg);
                  transform: rotateX(80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0);
                  transform: rotateX(0);
          opacity: 1;
        }
      }
      @keyframes flip-in-hor-bottom {
        0% {
          -webkit-transform: rotateX(80deg);
                  transform: rotateX(80deg);
          opacity: 0;
        }
        100% {
          -webkit-transform: rotateX(0);
                  transform: rotateX(0);
          opacity: 1;
        }
}
@keyframes warningFlash {
        50% {
                background-color: #fdd835;
        }
}
      
.slideIn {
    animation: 0.5s slideIn forwards;
}
.slideInRight {
    animation: 0.5s slideInRight forwards;
}
.quickAppear {
    animation: 0.3s appear forwards;
}
.appear {
    animation: 1s appear forwards;
}
.slowAppear {
    animation: 2s appear forwards;  
}
.rotateAppear {
    animation: 0.5s rotateAppear forwards;
}
.shake-top {
	-webkit-animation: shake-top 3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both infinite;
	        animation: shake-top 3s cubic-bezier(0.455, 0.030, 0.515, 0.955) both infinite;
}
.slide-out-blurred-tr {
	-webkit-animation: slide-out-blurred-tr 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-out-blurred-tr 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}
.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.warningFlash {
        animation: warningFlash 1s linear infinite
}